<template>
<!-- 巡检任务 -->
  <div class="tabs">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="业务工单" name="first">
        <Dict v-if="activeName=='first'"></Dict>
      </el-tab-pane>
      <el-tab-pane label="咨询工单" name="fourth">
        <collec v-if="activeName=='fourth'"></collec>
      </el-tab-pane>
      <el-tab-pane label="投诉工单" name="second">
        <Cycle v-if="activeName=='second'"></Cycle>
      </el-tab-pane>
    </el-tabs>
    <div class="buttonAdd">
      <el-button type="primary" @click="addRepairs" size="mini"
       v-if="$anthButtons.getButtonAuth('addpandanorder')"
      >添加报修记录</el-button>
    </div>
  </div>
</template>
<script>
import Dict from './dict.vue'
import Cycle from './cycle.vue'
import collec from './collec.vue'
export default {
  name: 'Index',
  components: { Dict , Cycle ,collec   },
  data() {
    return {
      activeName: 'first',
      options:[],
      value:''
    }
  },
  mounted() {
    if(this.$route.query.first ) {
      this.activeName = this.$route.query.first
    }
  },
  methods: {
    handleClick(tab, event) {
      this.$router.push({ path: '/home/repairRecord', query: { first: this.activeName } })
    },
    // 添加报修记录
    addRepairs(){
      console.log('添加报修记录')
      this.$router.push({ path: '/home/repairManage/options/collecAdd', query: { first: this.activeName } })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  // padding: 10px;
}
.tabs{
  margin: 10px;
  background: #ffffff;
  border-radius: 6px;
  padding:0 20px;
  position: relative;
}
.buttonAdd{
  position: absolute;
  top: 5px;
  right: 20px;
}
</style>
