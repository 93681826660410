<template>
  <div class="contain" style="margin:0">
    <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :inline="true"
        :model="paramsData"
      >
        <el-form-item label="时间段" prop="equipmentStatus" label-width="54px">
          <!-- @change="search" -->
          <el-date-picker
            
            size="mini"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="报修类型" prop="equipmentStatus" style="margin-left: 30px;">
          <el-cascader
                  size="mini"
                  multiple
                  style="width: 170px"
                  :options="selectDownArray"
                  v-model="selectDownArraylist"
                  :props="{ label: 'name', value: 'code',emitPath:false,multiple: true}"
                  separator=">"
                  clearable
                  @change="deformation"
                  collapse-tags>
                  
                </el-cascader>
        </el-form-item>
        <el-form-item label="来源" prop="equipmentStatus" label-width="70px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.source"
            placeholder="请选择来源"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录人" prop="equipmentStatus" label-width="80px">
          <el-input
          clearable
          size="mini"
            placeholder="请输入记录人"
            v-model="paramsData.createBy"
            style="width:170px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="状态" prop="equipmentStatus" label-width="70px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.status"
            placeholder="请选择状态"
            @change="search"
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="超时" prop="equipmentStatus" label-width="70px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.isOvertime"
            placeholder="请选择超时"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in overtime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="0px">
							<el-input
								placeholder="请输入关键字"
                size="mini"
								v-model="paramsData.keyword"
							>
								<el-select
									placeholder="请选择"
									v-model="paramsData.keyType"
									slot="prepend"
								>
									<el-option
										v-for="item in searchBar"
										:key="item.id"
										:label="item.label"
										:value="item.id"
									></el-option>
								</el-select>
							</el-input>
						</el-form-item>
       <el-form-item label="" prop="" style="margin-left: 20px;">
        <el-button
          size="mini"
          type="primary"
          @click="search"
          style="margin-left:10px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resize"
          >重置</el-button
        ><el-button
          size="mini"
          type="primary"
          @click="exportDetil"
          v-if="$anthButtons.getButtonAuth('deriveLinetask')"
          >导出</el-button
        >
         </el-form-item>
      </el-form>
    </div>
    <div class="modalityCenter">
     <!-- <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="addGroup"
          >添加</el-button> -->
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 375px)"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
     <el-table-column type="index" align="left" label="序号" width="60"></el-table-column>
      <el-table-column property="repairTypeName" label="报修类型" align="left" show-overflow-tooltip width="200px">
      </el-table-column>

      <el-table-column property="residentTypeName"
        label="用户类型"
        align="left"
        width="120px"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        property="residentName"
        label="关联用户"
        align="left"
        width="120px"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        property="contactName"
        label="联系人"
        align="left"
        width="150px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column align="center" prop="contactMobile" label="联系电话" width="120px" show-overflow-tooltip>
       <!-- <template slot-scope="{row}">
          <div class="examine" style="color:#2C9EF7"  @click="seeKm(row)">{{(row.jsL&&row.jsL!=-1)?row.jsL:'查看'}}</div>
        </template> -->
      </el-table-column>
      <el-table-column
        property="contactMobileEx"
        label="备用联系电话"
        align="left"
         width="120px"
      ></el-table-column>
      <el-table-column
        property="address"
        label="报修地址"
        align="left"
        width="150px"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        property="remark"
        label="备注"
        align="left"
        width="200px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        property="priorLevelName"
        label='优先级'
        align="left"
        width="150px"
      >
      </el-table-column>
      <el-table-column
        property="createBy"
        label="记录人"
        align="left"
        width="100px"
        show-overflow-tooltip
      ></el-table-column>
      
      <el-table-column property="createTime" width="185" label="创建时间" align="left">
        <!-- <template slot-scope="{ row }">
          {{
            row.isRedeploy == "3"
              ? ''
              : `${String(row.taskDurationStartTime).replaceAll("-", "/")} - ${String(row.taskDurationEndTime).replaceAll("-", "/")}`
          }}
        </template> -->
      </el-table-column>
      <el-table-column
        property="taskStatusName"
        label="状态"
        width="90"
        align="center">
        <template slot-scope="{ row }">
          <div :style="{color:row.status == 1 ? 'red' : ''}">{{row.statusName}}</div>
        </template>
      </el-table-column>
      <el-table-column
        property="isOvertime"
        label="超时"
        align="left">
      </el-table-column>
      <el-table-column label="操作" align="left" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            v-if="$anthButtons.getButtonAuth('lookbusiness')"
            @click="IsOpenFun(4, row)"
            >查看</el-button
          >
          <el-button type="primary" plain  
          v-if="row.status == 1 && $anthButtons.getButtonAuth('redeploybusiness')"
           @click="handle(row)" size="mini"
            >转派</el-button
          >
          <!-- <el-button
            type="text"
            @click="del(row)"
            size="small"
            style="color:#FF5400"
            v-if="row.taskGenerateStatus == 0"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: left">
      <el-pagination
        @size-change="handleSizeChange"
        style="margin-top: 10px"
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        :page-sizes="[10,30, 50, 100]"
        :page-size="paramsData.size"
        :current-page="paramsData.current"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    </div>

  
    <!-- 转派 -->
    <el-dialog :title="titles" :visible.sync="redeployVisible" @closed="CloseChangePlan" width="460px">
      <el-form :model="DeviceData"  ref="DeviceData" label-width="80px" :rules="rules">
        <el-form-item label="受理部门" prop="deptId">
          <el-cascader
                v-model="DeviceData.deptId"
                :options="treedata"
                size="mini"
                style="width: 338px"
                :props="TreeProps"
                :show-all-levels="false"
                ref="cascaderHandle"
                @change="close"
            >
            </el-cascader>
        </el-form-item>
        <el-form-item label="受理人" >
          <el-select style="width: 338px" v-model="DeviceData.userId" size="mini">
            <el-option
              v-for="item in maintainUserList"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="redeployVisible = false" >取 消</el-button>
        <el-button size="small" @click="submitRedeploy" type="primary">确 认</el-button>
      </div>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog :visible.sync="remarkBool" width="460px">
       <el-descriptions  :column="1" :label-style="{
					color: '#909399',
					width: '100px',
					fontsize: '14px',
				}"
				:contentStyle="{ fontsize: '14px', color: '#303133' }">
          <el-descriptions-item label="报修类型">{{remarkData.repairTypeName}}</el-descriptions-item>
          <el-descriptions-item label="用户类型">{{remarkData.residentTypeName}}</el-descriptions-item>
          <el-descriptions-item label="关联用户">{{remarkData.residentName}}</el-descriptions-item>
          <el-descriptions-item label="联系人">{{remarkData.contactName}}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{remarkData.contactMobile}}</el-descriptions-item>
          <el-descriptions-item label="备用联系电话">{{remarkData.contactMobileEx}}</el-descriptions-item>
          <el-descriptions-item label="报修地址">{{remarkData.address}}</el-descriptions-item>
          <el-descriptions-item label="备注">{{remarkData.remark}}</el-descriptions-item>
          <el-descriptions-item label="优先级">{{remarkData.priorLevelName}}</el-descriptions-item>
          <el-descriptions-item label="来源">{{remarkData.sourceName}}</el-descriptions-item>
      </el-descriptions>
       <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="submitRemark()" type="primary"  v-if="$anthButtons.getButtonAuth('updatabusiness')">编辑</el-button>
        <el-button size="small" @click="remarkBool = false"  >取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectAllUsersByDeptId
} from "@/RequestPort/maintenance";
import {
  dicpage
} from "../../RequestPort/Login/LoginRequest";
import { YWorderPage,YWorderDetails,YWtransfer } from "@/RequestPort/serviceOrders";
import {
  selectSysDeptTreeList
} from '@/RequestPort/user/user'
export default {
  name: "inspeTask",
  data() {
    return {
        // 部门
        treedata:[],
        TreeProps:{
          label: 'name',
          value:'id',
          children:'children',
          checkStrictly: true,
          emitPath:false
        },
        //报修类型
        selectDownArraylist:[],
        // 受理人
        maintainUserList:[],
        // 详情
        remarkData:{}, 
        // 备注弹窗
        remarkBool:false,
        // 转派弹窗
        redeployVisible:false,
        //状态
        options: [{
          value: '1',
          label: '待派单'
        }, {
          value: '2',
          label: '已派单'
        }
        ],
        // 是否超时
        overtime:[{
          value: '2',
          label: '已超时'
        }, {
          value: '1',
          label: '未超时'
        }
        ],
       // 关键字条件
				searchBar: [
					{ label: "联系人", id: 1, name: "residentName" },
					{ label: "联系电话", id: 2, name: "contactName" },
					{ label: "报修地址", id: 3, name: "mobile" },
					{ label: "备注", id: 4, name: "address" },
				],
        // 查询条件
      paramsData: {
        current: 1,
        size: 100,
        taskType:1,
        taskGenerateStatus:null,
        keyWordValue:'',
        keyType: 1,
      },
      rules: {
        deptId: [{ required: true, message: "请选择受理部门", trigger: "change" }],
      },
      
      // 新增任务
      addVisible: false,
      // 总条数
      total: 0,
      // 查询暂存的时间段
      DataPicker: [],
      // 任务列表
      tableData: [],
      // 报修类型
      selectDownArray: [],
      // 来源
      excutorDownArray: [
        {
          id: '1',
          username: '电话'
        }, {
          id: '2',
          username: '大厅'
        },{
          id: '3',
          username: '维修员'
        }
      ],
      // 转派对象
      DeviceData: {
        userId:'',
        deptId:''
      },
      // 弹窗
      titles:'',
      // 受理部门
      excutorSpecialArray:[]
    };
  },
  watch:{
  },
  mounted() {
    let that = this
    // 选择报修类型（业务工单下的分类）
    dicpage({platform:6}).then(res => {
        if(res.code == 200) {
          if(res.data && res.data.length>0) {
            res.data.forEach(el => {
              if(el.code == "bxlx") {
                if(el.children && el.children.length>0){
                  el.children.forEach(item => {
                    if(item.code == "ywgd") {
                      that.selectDownArray = item.children
                    }
                  })
                }
              }
            })
          }
          
        }
    });
    // 获取列表
    this.loadlist(1);
  },
  methods: {
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
      
    /**
     * 查看弹窗
     */
    IsOpenFun(e, row) {
      YWorderDetails({id:row.id}).then(res => {
        console.log(res)
        this.remarkData = res.data
      })
      this.remarkBool = true
      
    },
    /**
     * 编辑
     */
    submitRemark(){
      this.remarkBool = false
      this.$router.push({ path: '/home/repairManage/options/collecAdd', query: { first: 'first',id: this.remarkData.id} })
    },
    
    /**
     * 编辑新增关闭事件
     */
    CloseChangePlan() {
      this.DeviceData = {
        userId:'',
        deptId:''
      };
      this.$refs.DeviceData.resetFields()
    },
    /**
     * 转派
     */
    handle(row) {
      this.titles = '转派'
      this.DeviceData.id = row.id 
      // 获取受理部门
      selectSysDeptTreeList({deptListShow:'n'}).then((e)=>{
      let tree=e.data
      if(tree[0]){
        tree[0].disabled=true
      }
      // 特殊处理
      if(tree[0].children) {
        tree[0].children.forEach(el => {
          if(el.id == 8) {
            el.disabled=true
          }
        });
      }
      this.treedata = tree
    })
      this.redeployVisible = !this.redeployVisible;
    },
    /**
     * 选择完受理部门获取受理人
     */
    close(val){
      this.DeviceData.userId = ''
      selectAllUsersByDeptId({deptId:val}).then(res => {
        this.maintainUserList = res.data
      })
    },
    /**
     * 转派提交
     */
    submitRedeploy(){
        this.$refs.DeviceData.validate((valid) => {
        if (valid) {
          
          YWtransfer(this.DeviceData).then(res => {
            this.$message.success(res.data);
            this.loadlist(1);
            this.redeployVisible = false
          })
        }
      })
    },
    deformation(){
      let text = []
      this.selectDownArraylist.forEach(el => {
        text.push(el)
      })
      this.paramsData.repairType = text.join(',')
      // this.loadlist(1)
    },
    /**
     * 查询
     */
    search() {
      if (this.DataPicker&&this.DataPicker.length > 0) {
        this.paramsData.startTime = this.DataPicker[0];
        this.paramsData.endTime = this.DataPicker[1];
      }else{
        this.paramsData.startTime =null;
        this.paramsData.endTime = null;
      }
      this.paramsData.current = 1;
      this.loadlist();
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.loadlist();
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.loadlist(1);
    },

  
  /**
   * 重置
   */
    resize() {
      this.paramsData = {
        current: 1,
        size: 100,
        taskType:1,
        keyType:1,
        searchText: "",
      };
      this.selectDownArraylist = []
      this.DataPicker = [];
      this.loadlist(1);
    },
    /**
     * 获取列表
     */
    loadlist(obj) {
      if(obj){
        this.paramsData.current = 1
      }
      YWorderPage(this.paramsData).then((res) => {
        res.data.records.forEach(item => {
            item.jsL='-1'
          });
        this.tableData = res.data.records;
        // this.tableData = [{}]
        this.total = res.data.total;
      });
    },
  },
  
};
</script>

<style scoped lang="less">
::v-deep .el-input-group__prepend {
		width: 67px;
	}
  ::v-deep .el-form-item__content .el-input-group{
        vertical-align: initial;
  }
.point {
  width: 100%;
  
}
.contain{
  background-color:transparent;
  padding: 0;
}
// 搜索栏样式
.modalityTop{
    // border: 1px solid #ecedf1;
    // padding: 20px 0;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // 面包屑
  // .crumbs{
  //   padding: 10px 15px 20px;
  //   span{
  //     color: #3069E1;
  //   }
  // }
  // .el-form-item{
  //   margin-left: 20px;
  // }
  // .el-form-item__label{
  //   text-align: justify;
  // }
}
// 搜索
.searchForm{
  display: flex;
  flex-direction: row;
   flex-wrap: wrap;
    // justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  // padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
//    ::v-deep.warning-row{
//     background: #F9FAFC;
//      .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
    
//      }
//   }
// ::v-deep.success-row{ 
//   background: #ffffff;
//   .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
//   }
// }
}
// ::v-deep.el-table th.el-table__cell>.cell{
//   font-size: 15px;
// }
  .el-pagination{
        margin: 15px 0 15px;
  }
  // ::v-deep.el-date-picker{
  //   top: 521px!important;
  //   left: 477px!important;
  // }
</style>
