<template>
  <div style="margin:0">
    <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :model="paramsData"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus" label-width="55px">
          <el-date-picker
            
            size="mini"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          <!-- @change="search" -->
          </el-date-picker>
        </el-form-item>
      
        <el-form-item label="用户类型" prop="equipmentStatus" style="margin-left:14px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.residentType"
            placeholder="请选择用户类型"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投诉类型" prop="equipmentStatus" style="margin-left: 14px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.complainType"
            placeholder="请选择投诉类型"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in complaintType"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源" prop="equipmentStatus" label-width="50px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.source"
            placeholder="请选择来源"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in sourceType"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="equipmentStatus" label-width="50px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.solveStatus"
            placeholder="请选择状态"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in optionStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录人" prop="equipmentStatus" label-width="70px">
          <el-input
          clearable
          size="mini"
            placeholder="请输入关键字"
            v-model="paramsData.createBy"
            style="width:250px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="被投诉人" prop="equipmentStatus">
          <el-input
          clearable
          size="mini"
            placeholder="请输入关键字"
            v-model="paramsData.complainedUser"
            style="width:250px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="解决人" prop="equipmentStatus">
          <el-input
          clearable
          size="mini"
            placeholder="请输入关键字"
            v-model="paramsData.solveUser"
            style="width:250px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="超时" prop="equipmentStatus" label-width="50px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.isOvertime"
            placeholder="请选择超时 "
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in overtimeStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="14px">
							<el-input
								placeholder="请输入关键字"
                size="mini"
								v-model="paramsData.keyword"
							>
								<el-select
									placeholder="请选择"
									v-model="paramsData.keyType"
									slot="prepend"
								>
									<el-option
										v-for="item in searchBar"
										:key="item.id"
										:label="item.label"
										:value="item.id"
									></el-option>
								</el-select>
							</el-input>
						</el-form-item>
       <el-form-item label="" prop="" label-width="0">
        <el-button
          size="mini"
          type="primary"
          @click="search"
          style="margin-left:10px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resize"
          >重置</el-button
        >
         </el-form-item>
      </el-form>
    </div>
    <!-- <el-divider style="color: #f2f2f2"></el-divider> -->
    <div class="modalityCenter">
     <!-- <div class="tableTool">
        <el-button
          size="mini"
          type="primary"
          class="addButton"
          v-if="$anthButtons.getButtonAuth('addLinetaskInstancy')"
          @click="addItem()"
          >添加</el-button>
      </div> -->
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 373px)"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
     <el-table-column type="index" align="left" label="序号" width="60"></el-table-column>
      <el-table-column property="residentTypeName" label="用户类型" align="left" width="120px" show-overflow-tooltip >
      </el-table-column>
      <el-table-column
        property="residentName"
        label="关联用户"
        align="left"
        width="120px"
      >
      </el-table-column>
      <el-table-column
        property="contactName"
        label="联系人"
        align="left"
        width="150"
      >
      </el-table-column>
      <el-table-column
        property="contactMobile"
        label="联系电话"
        width="150"
        align="left">
      </el-table-column>
      <el-table-column
        property="contactMobileEx"
        label="备用联系电话"
        align="left"
        width="180"
      ></el-table-column>
      
      <el-table-column property="complainType" label="投诉类型" align="left" width="180">
      </el-table-column>
      <el-table-column
        property="complainedUser"
        label="被投诉人"
        width="150"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="event"
        label="事件"
        width="150"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="isValid"
        label="有效投诉"
        align="left">
      </el-table-column>
      <el-table-column
        property="sourceName"
        label="来源"
        align="left">
      </el-table-column>
      <el-table-column
        property="createBy"
        label="记录人"
        width="120"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="createTime"
        label="创建时间"
        width="200"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="solveUser"
        label="解决人"
        width="100"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="solveRemark"
        label="备注"
        width="150"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="solveTime"
        label="解决时间"
        width="200"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="isOvertime"
        label="超时"
        width="80"
        show-overflow-tooltip
        align="left">
      </el-table-column>
      <el-table-column
        property="solveStatusName"
        label="状态"
        fixed="right"
        align="left">
          <template slot-scope="{ row }">
            <div :style="{color:row.solveStatus == 1 ? 'red' : ''}">{{row.solveStatusName }}</div>
          </template>
      </el-table-column>
      <el-table-column label="操作" align="left" width="150" fixed="right">
        <template slot-scope="{ row }">
          
          <el-button
            type="primary" plain
            @click="addItem(row)"
            v-if="$anthButtons.getButtonAuth('updatacomplaint')"
            size="mini"
            >编辑</el-button>
            <el-button
            type="primary"
            size="mini"
            v-if="row.solveStatus == 1 && $anthButtons.getButtonAuth('solvecomplaint')"
            @click="IsOpenFun(4, row)"
            >解决</el-button
          >
        </template>
      </el-table-column>
    </el-table> 

    <div style="text-align: left">
      <el-pagination
        @size-change="handleSizeChange"
        style="margin-top: 10px"
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="paramsData.size"
        :current-page="paramsData.current"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    </div>
  
    <!-- 解决 -->
    <el-dialog :visible.sync="remarkBool" width="460px" :close-on-click-modal='false' title="解决">
       <el-form :model="remarkData"   ref="DeviceData" label-width="80px" :rules="rules">
        <el-form-item label="有效投诉" prop="isValid">
              <el-radio-group
                v-model="remarkData.isValid"
              >
                <el-radio :label="2">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
        <el-form-item label="备注" prop="solveRemark">
          <el-input
            style="width: 100%"
            maxlength="200"
            type="textarea"
            show-word-limit
            placeholder="请输入备注"
            v-model="remarkData.solveRemark"
          ></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="remarkBool = false"  >取 消</el-button>
        <el-button size="small" @click="submitRemark" type="primary"  >确 认</el-button>
        
      </span>
    </el-dialog>
   
  </div>
</template>

<script>
import { TSorderPage,TSsolve } from "@/RequestPort/serviceOrders";
export default {
  name: "inspeTask",
  data() {
    return {
        // 解决
        remarkData:{
          isValid:1,
          taskRemark:''
        }, 
        // 解决弹窗
        remarkBool:false,
        // 查询条件
      paramsData: {
        current: 1,
        size: 100,
        keyType: 1,
      },
      // 关键字条件
				searchBar: [
					{ label: "联系人", id: 1, name: "residentName" },
					{ label: "联系电话", id: 2, name: "contactName" },
					{ label: "事件", id: 3, name: "mobile" },
				],
      rules: {
        isValid: [
          { required: true, message: "请选择是否是有效投诉", trigger: "change" },
        ],
      },
      // 总条数
      total: 0,
      // 查询暂存的时间段
      DataPicker: [],
      // 列表数据
      tableData: [],
      // 用户类型
      excutorDownArray: [
        { label: "居民", id: 1, name: "residentName" },
				{ label: "非居民", id: 2, name: "contactName" },
      ],
      // 投诉类型
      complaintType:[
        { label: "服务", id: 1, name: "residentName" },
				{ label: "其他", id: 2, name: "contactName" },
      ],
       // 来源
      sourceType:[
        { label: "电话", id: 1, name: "residentName" },
				{ label: "大厅", id: 2, name: "contactName" },
      ],
      // 状态
      optionStatus:[
        { label: "未解决", id: 1, name: "residentName" },
				{ label: "已解决", id: 2, name: "contactName" },
      ],
      // 超时
      overtimeStatus:[
        { label: "未超时", id: 1, name: "residentName" },
				{ label: "已超时", id: 2, name: "contactName" },
      ],
    };
  },
  watch:{
  },
  mounted() {
    // 获取列表
    this.loadlist(1);
  },
  methods: {
    /**
     * 编辑添加
     */
    addItem(row){
      this.$router.push({ path: '/home/repairManage/options/collecAdd', query: { first: 'second',id: row.id} })
    },
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
    /**
     * 解决弹窗
     */
    IsOpenFun(e, row) {
      this.remarkData={
          isValid:1,
          taskRemark:''
        }, 
      this.remarkBool = true
      this.remarkData.id = row.id
    },
    /**
     * 备注提交
     */
    submitRemark(){
      this.$refs.DeviceData.validate((valid) => {
        if (valid) {
          TSsolve(this.remarkData).then(res => {
            this.$message.success(res.data);
            this.loadlist(1);
            this.remarkBool = false
          })
        }
      })
    },
    /**
     * 查询
     */
    search() {
      if (this.DataPicker&&this.DataPicker.length > 0) {
        this.paramsData.startTime = this.DataPicker[0];
        this.paramsData.endTime = this.DataPicker[1];
      }else{
        this.paramsData.startTime =null;
        this.paramsData.endTime = null;
      }
      this.loadlist(1);
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.loadlist();
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.loadlist(1);
    },
  /**
   * 重置
   */
    resize() {
      this.paramsData = {
        current: 1,
        size: 100,
        keyType:1,
        searchText: "",
      };
      this.DataPicker = [];
      this.loadlist(1);
    },
    /**
     * 获取列表
     */
    loadlist(val) {
      if(val) {
        this.paramsData.current = 1
      }
      TSorderPage(this.paramsData).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
  },
  
};
</script>

<style scoped lang="less">
::v-deep .el-input-group__prepend {
		width: 67px;
	}
  ::v-deep .el-form-item__content .el-input-group{
        vertical-align: initial;
  }
.point {
  width: 100%;
  
}

// 搜索栏样式
// .modalityTop{
    // border: 1px solid #ecedf1;
    // padding: 20px 0;
    // border-radius: 5px;
    // background: #ffffff;
    // margin-bottom: 12px;
  // 面包屑
  // .crumbs{
  //   padding: 10px 15px 20px;
  //   span{
  //     color: #3069E1;
  //   }
  // }
  // .el-form-item{
  //   margin-left: 20px;
  // }
  // .el-form-item__label{
  //   text-align: justify;
  // }
// }
// 搜索
.searchForm{
  display: flex;
  flex-direction: row;
   flex-wrap: wrap;
    // justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  margin-top: 10px;
  // padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
//    ::v-deep.warning-row{
//     background: #F9FAFC;
//      .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
    
//      }
  }
// ::v-deep.success-row{ 
//   background: #ffffff;
//   .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
//   }
// }
// }
// ::v-deep.el-table th.el-table__cell>.cell{
//   font-size: 15px;
// }
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
.el-pagination{
        margin: 15px 0px 15px;
  }
  // ::v-deep.el-date-picker{
  //   top: 521px!important;
  //   left: 477px!important;
  // }
</style>
